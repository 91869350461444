import { takeLatest, call, put } from 'redux-saga/effects';
import { api } from 'utils/request';

import { INIT_APP } from './constants';
import { setCurrentUser, setLoading, setLoaded } from './actions';

const apiInst = api();

function* initializeApp() {
  try {
    const { data } = yield call(apiInst.get, '/api/v2/profile');

    yield put(setCurrentUser(data));

    yield put(setLoaded(true));
    yield put(setLoading(false));
  } catch (e) {
    console.error(e);
    yield put(setLoaded(false));
    yield put(setLoading(false));
  }
}

function* watchAll() {
  yield takeLatest(INIT_APP, initializeApp);
}

export default watchAll;
