import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import BackIcon from 'icons/backArrow';

import styles from './styles.module.scss';

function Header(props) {
  return (
    <div className={styles.headerWrapper}>
      {props.backButtonPath && (
        <RouterLink aria-label="back" to={props.backButtonPath}>
          <BackIcon color="primary" className={styles.backIcon} />
        </RouterLink>
      )}
      <Container maxWidth={false} disableGutters>
        <Grid container alignItems="center">
          <Grid item xs>
            {typeof props.title === 'string' ?
              <Typography variant="h1">{props.title}</Typography>
            : props.title}
          </Grid>

          {props.children && <Grid item>{props.children}</Grid>}
        </Grid>
      </Container>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  backButtonPath: PropTypes.string,
  children: PropTypes.any,
};

export default Header;
