import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

function Image({ src, ImageStub, ...props }) {
  const [imageUrl, setImageUrl] = React.useState(null);
  const setImageUrlCallback = useCallback(() => setImageUrl(src), [src]);
  const resetImageUrlCallback = useCallback(() => setImageUrl(null), []);

  useEffect(() => {
    const img = document.createElement('img');
    if (src) {
      img.src = src;

      img.addEventListener('load', setImageUrlCallback);
      img.addEventListener('error', resetImageUrlCallback);
    }

    return () => {
      img.removeEventListener('load', setImageUrlCallback);
      img.removeEventListener('error', resetImageUrlCallback);
    };
  }, [src]);

  if (imageUrl === null) {
    return <ImageStub />;
  }

  return <img alt="" src={imageUrl} {...props} />;
}

Image.propTypes = {
  src: PropTypes.string,
  ImageStub: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  className: PropTypes.string,
};

export default Image;
