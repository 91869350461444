import React, { useCallback } from 'react';

import Layout from 'containers/Layouts/Auth';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

function ErrorFallback() {
  const reload = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    window.location.reload();
  }, []);

  return (
    <Layout>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography align="center" variant="h4">
            Something went wrong
          </Typography>
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <Typography>
            Please try to{' '}
            <a href="#" onClick={reload}>
              reload page
            </a>
            , if this doesn&prime;t help - contact support!
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default ErrorFallback;
