import { dateFormat } from 'utils/formatters';

export default ({ t }) => [
  {
    field: 'fullName',
    headerName: t('common:full_name'),
    headerAlign: 'left',
    flex: 1,
    width: 250,
    editable: false,
    sortable: false,
  },
  {
    field: 'phone_number',
    headerName: t('common:phone_number'),
    headerAlign: 'left',
    flex: 1,
    minWidth: 300,
    editable: false,
    align: 'left',
    sortable: false,
    valueGetter: ({ row }) => `+${row.phoneNumber}`,
  },
  {
    field: 'createdAt',
    headerName: t('common:created_at'),
    headerAlign: 'left',
    flex: 1,
    width: 250,
    editable: false,
    sortable: false,
    valueGetter: ({ row }) => dateFormat(row.createdAt),
  },
  {
    field: 'declined',
    headerName: t('declined'),
    headerAlign: 'left',
    flex: 1,
    width: 250,
    editable: false,
    sortable: true,
    valueGetter: ({ row }) => (row.declined ? t('common:yes') : t('common:no')),
  },
];
