/**
 *
 * Asynchronously loads the component for FamilyTasksPage
 *
 */

import loadable from 'utils/loadable';

export default loadable(
  () =>
    import(
      // webpackChunkName: "FamilyTasksPage"
      './index'
    ),
);
