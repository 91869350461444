import { SET_DASHBOARD_INFO, SET_LOADING, FETCH_DASHBOARD_INFO } from './constants';

export function fetchDashboardInfo() {
  return {
    type: FETCH_DASHBOARD_INFO,
  };
}

export function setDashboardInfo(data) {
  return {
    type: SET_DASHBOARD_INFO,
    payload: data,
  };
}

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    payload: isLoading,
  };
}
