/* eslint-disable import/no-import-module-exports */
import React from 'react';
import { ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './containers/App';
import history from './utils/history';
import configureStore from './configureStore';
import reportWebVitals from './reportWebVitals';
import createTheme from './theme';
/* eslint-enable import/no-import-module-exports */
import './styles/index.scss';
const theme = createTheme();

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const render = () => {
  ReactDOM.createRoot(MOUNT_NODE).render(
    <Provider store={store}>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </StyledEngineProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>,
  );
};

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
