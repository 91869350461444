import { createSelector } from 'reselect';
import get from 'lodash/fp/get';

import { initialState } from './reducer';

const selectMembershipRequests = (state) => state.MembershipRequestsPage || initialState;

export const makeSelectLoading = () => createSelector(selectMembershipRequests, get('isLoading'));
export const makeMembershipRequestsSelector = () =>
  createSelector(selectMembershipRequests, get('membershipRequests'));
