import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from 'components/ErrorFallback';

import LoadingIndicator from 'components/LoadingIndicator';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { initApp } from './actions';

import * as selectors from './selectors';

import saga from './saga';
import reducer from './reducer';

function Protected(props) {
  useInjectSaga({ key: 'global', saga });
  useInjectReducer({ key: 'global', reducer });

  useEffect(() => {
    props.dispatch(initApp());
  }, []);

  if (props.loading) {
    return <LoadingIndicator />;
  }

  if (!props.loaded) {
    return null;
  }

  const Layout = props.layoutComponent;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={console.log}>
      <Layout logoUrl={props.logoUrl} dispatch={props.dispatch} user={props.user}>
        <Outlet />
      </Layout>
    </ErrorBoundary>
  );
}

Protected.propTypes = {
  dispatch: PropTypes.func.isRequired,
  layoutComponent: PropTypes.elementType.isRequired,
  logoUrl: PropTypes.string,
  user: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectors.makeUserSelector(),
  loading: selectors.makeSelectLoading(),
  loaded: selectors.makeSelectLoaded(),
});

export default compose(
  memo,
  connect(mapStateToProps, (dispatch, ownProps) => ({ dispatch: ownProps.dispatch || dispatch })),
)(Protected);
