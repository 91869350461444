/**
 *
 * DashBoardPage
 *
 */

import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import * as selectors from './Reducer/selectors';
import { fetchDashboardInfo } from './Reducer/actions';
import Content from './Content';
import saga from './Reducer/saga';
import reducer from './Reducer/reducer';

function DashboardPage(props) {
  useInjectSaga({ key: 'dashboardPage', saga });
  useInjectReducer({ key: 'dashboardPage', reducer });

  const { t } = useTranslation('header_main');

  useEffect(() => {
    props.dispatch(fetchDashboardInfo());
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t('dashboard')} | Enola`}</title>
      </Helmet>
      <Content
        dashboardInfo={props.dashboardInfo}
        isLoading={props.isLoading}
        dispatch={props.dispatch}
      />
    </>
  );
}

DashboardPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dashboardInfo: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const mapStateToProps = createStructuredSelector({
  dashboardInfo: selectors.makeDashboardInfoSelector(),
  isLoading: selectors.makeSelectLoading(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(memo, withConnect)(DashboardPage);
