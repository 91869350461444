import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import FormHelperText from '@mui/material/FormHelperText';

const CustomSelect = React.forwardRef(
  (
    {
      label,
      value,
      onChange,
      options,
      fullWidth = false,
      error,
      helperText,
      translate,
      FormHelperTextProps,
      classes,
      required,
      ...restProps
    },
    ref,
  ) => {
    return (
      <FormControl fullWidth={fullWidth}>
        {label && (
          <InputLabel
            required={required}
            className={classes.inputLabel}
            classes={{ root: 'label' }}
          >
            {label}
          </InputLabel>
        )}
        <Select
          ref={ref}
          id={`select-for-${label}-id`}
          value={value}
          onChange={onChange}
          {...restProps}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {translate(option.label)}
            </MenuItem>
          ))}
        </Select>
        {helperText && (
          <FormHelperText error={error} {...FormHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

CustomSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  FormHelperTextProps: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default compose(
  memo,
  withStyles((theme) => ({
    inputLabel: {
      color: theme.uiColors.neutral.second[900],
    },
  })),
)(CustomSelect);
