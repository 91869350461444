/**
 *
 * Asynchronously loads the component for FamiliesPage
 *
 */

import loadable from 'utils/loadable';

export { default as NewFamilyPage } from './NewFamily/Loadable';
export { default as ViewFamilyPage } from './ViewFamily/Loadable';

export default loadable(
  () =>
    import(
      // webpackChunkName: "FamiliesPage"
      './index'
    ),
);
