/**
 *
 * CardInfo
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import cs from 'clsx';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import Button from 'components/Button';
import LoadingIndicator from 'components/LoadingIndicator';
import { ReactComponent as CircleImage } from '../assets/circle.svg';

import s from './styles.module.scss';

function CardInfo({ displayValue, title, from, isLoading }) {
  const { t } = useTranslation(['family_information']);

  const theme = useTheme();

  return (
    <Box className={s.container} bgcolor={theme.uiColors.neutral.second[100]}>
      <Box width="100%" justifyContent="flex-start" display="flex">
        <Typography fontSize={20} variant="h1">
          {title}
        </Typography>
      </Box>
      {isLoading && <LoadingIndicator />}
      <Box className={`${s.fadeIn} ${!isLoading ? s.visible : ''} ${s.content}`}>
        <CircleImage
          className={cs({
            [s.rotateVolun]: from === 'volunteers',
            [s.rotateTask]: from === 'tasks',
          })}
        />
        <Typography className={s.displayValue} variant="h1">
          {displayValue}
        </Typography>
      </Box>
      <Box width="100%" justifyContent="flex-end" display="flex">
        <Button sx={{ backgroundColor: '#fff' }} size="medium">
          {t('more')}
        </Button>
      </Box>
    </Box>
  );
}

CardInfo.propTypes = {
  displayValue: PropTypes.number,
  title: PropTypes.string,
  from: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default compose(memo)(CardInfo);
