import axios from 'axios';
import { camelizeKeys } from 'humps';

import { getSession, clearSession } from 'utils/sessions';
import basePath from './basePath';

const responseHandler = (res) => {
  res.data = camelizeKeys(res.data);
  return res;
};

export const api = (httpMethod) => {
  const instance = axios.create();

  instance.interceptors.request.use((config) => {
    const session = getSession();

    config.headers.setAuthorization(session.user && session.user.token);

    return config;
  });

  instance.interceptors.response.use(responseHandler, (error) => {
    if (error.response.status === 401) {
      clearSession();
      window.location.href = basePath('sign-in');
    }

    return Promise.reject(error);
  });

  if (httpMethod !== undefined) {
    return instance[httpMethod];
  }

  return instance;
};
