import React from 'react';
import LanguageSelector from 'components/LanguageSelector';
import styles from './styles.module.scss';
import LogoPng from '../../images/enola-logo.png';

function AppHeader() {
  return (
    <header className={styles.headerWrapper}>
      <p className={styles.headerTitle}>Dashboard</p>
      <div className={styles.headerContent}>
        <LanguageSelector />
        <div className={styles.headerLogo}>
          <img alt="header logo" src={LogoPng} />
        </div>
      </div>
    </header>
  );
}

export default AppHeader;
