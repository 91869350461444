/**
 *
 * MembershipRequestsPage
 *
 */

import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { useParams } from 'react-router-dom';
import * as selectors from './Reducer/selectors';
import { fetchMembershipRequests } from './Reducer/actions';
import Content from './Content';
import saga from './Reducer/saga';
import reducer from './Reducer/reducer';

function MembershipRequestsPage(props) {
  useInjectSaga({ key: 'MembershipRequestsPage', saga });
  useInjectReducer({ key: 'MembershipRequestsPage', reducer });

  const { t } = useTranslation('membership_requests');
  const { familyId } = useParams();
  useEffect(() => {
    props.dispatch(fetchMembershipRequests(familyId));
  }, []);
  return (
    <>
      <Helmet>
        <title>{`${t('membership_requests')} | Enola`}</title>
      </Helmet>
      <Content
        isLoading={props.isLoading}
        familyId={familyId}
        membershipRequests={props.membershipRequests}
        dispatch={props.dispatch}
      />
    </>
  );
}

MembershipRequestsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  membershipRequests: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const mapStateToProps = createStructuredSelector({
  membershipRequests: selectors.makeMembershipRequestsSelector(),
  isLoading: selectors.makeSelectLoading(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(memo, withConnect)(MembershipRequestsPage);
