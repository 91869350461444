/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import get from 'lodash/fp/get';

import { initialState } from './reducer';

const selectGlobal = (state) => state.global || initialState;

export const makeSelectLoading = () => createSelector(selectGlobal, get('loading'));

export const makeSelectLoaded = () => createSelector(selectGlobal, get('loaded'));

export const makeUserSelector = () => createSelector(selectGlobal, get('currentUser'));
