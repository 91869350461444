import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSelect from 'components/Select';

import Box from '@mui/material/Box';
import { LangIcon } from 'icons/language';

function LanguageSelector() {
  const { t, i18n } = useTranslation();

  const changeLanguage = useCallback(
    ({ target: { value } }) => {
      i18n.changeLanguage(value);
      localStorage.setItem('i18nextLng', value);
    },
    [i18n],
  );
  return (
    <Box>
      <CustomSelect
        IconComponent={LangIcon}
        options={[
          { label: 'English', value: 'en' },
          { label: 'עִברִית', value: 'he' },
        ]}
        variant="standard"
        onChange={changeLanguage}
        value={i18n.language}
        defaultValue={i18n.language}
        translate={t}
      />
    </Box>
  );
}

export default LanguageSelector;
