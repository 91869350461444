import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Hebrew from './locales/he.json';
import English from './locales/en.json';

export const resources = {
  en: English,
  he: Hebrew,
};

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'he'],
    fallbackLng: 'en',
    resources,
    // debug: process.env.NODE_ENV === 'development',
  });

export default i18next;
