import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import cs from 'clsx';
import { compose } from 'redux';
import DashboardIcon from 'icons/dashboard';
import FamilyIcon from 'icons/family';
import ResourcesIcon from 'icons/resources';
import HandIcon from 'icons/hand';
import HeartIcon from 'icons/heart';
import TaskIcon from 'icons/task';
import LogoutIcon from 'icons/logout';
import { useTranslation } from 'react-i18next';
import basePath from 'utils/basePath';
import { clearSession } from 'utils/sessions';
import LanguageSelector from 'components/LanguageSelector';
import NavBar from 'widgets/NavBar';
import AppHeader from 'widgets/AppHeader';
import styles from './styles.module.scss';
import baseLayout from '../baseLayout.module.scss';

function Layout(props) {
  const { t } = useTranslation('header_main');
  const navigate = useNavigate();

  const onLogout = useCallback(() => {
    clearSession();
    navigate(basePath('sign-in'));
  }, [navigate, clearSession]);

  const navigationItems = [
    {
      icon: <DashboardIcon />,
      label: t('dashboard'),
      disabled: false,
      navigateTo: basePath('dashboard'),
    },
    {
      icon: <HandIcon />,
      label: t('needs'),
      disabled: true,
      navigateTo: basePath('needs'),
    },
    {
      icon: <ResourcesIcon />,
      label: t('resources'),
      disabled: true,
      navigateTo: basePath('resources'),
    },
    {
      icon: <FamilyIcon />,
      label: t('families'),
      disabled: false,
      navigateTo: basePath('families'),
    },
    {
      icon: <TaskIcon />,
      label: t('tasks'),
      disabled: true,
      navigateTo: basePath('tasks'),
    },
    {
      icon: <HeartIcon />,
      label: t('supporters'),
      disabled: true,
      navigateTo: basePath('supporters'),
    },
  ];

  return (
    <section className={cs(baseLayout.wrapper)}>
      <div className={baseLayout.gridLayout}>
        <NavBar
          navigationItems={navigationItems}
          logOutComponent={{
            icon: <LogoutIcon />,
            label: t('log_out'),
            clickHandler: onLogout,
          }}
        />
        <div>
          <AppHeader />
          <main className={cs(baseLayout.content, styles.content, 'layout-container')}>
            {props.children}
          </main>
        </div>
      </div>
    </section>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default compose(memo)(Layout);
