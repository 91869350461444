import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'utils/request';

import { FETCH_DASHBOARD_INFO } from './constants';
import { setDashboardInfo, setLoading } from './actions';

const apiInst = api();

function* fetchDashboardInfo() {
  yield put(setLoading(true));
  try {
    const { data } = yield call(apiInst.get, '/api/v2/families');

    yield put(setDashboardInfo(data));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* watchAll() {
  yield takeLatest(FETCH_DASHBOARD_INFO, fetchDashboardInfo);
}

export default watchAll;
