/*
 * App Actions
 *
 */

import { INIT_APP, SET_CURRENT_USER, CLEAR, SET_LOADING, SET_LOADED } from './constants';

/**
 * Dispatched when the repositories are loaded by the request saga
 *
 * @param  {array} repos The repository data
 * @param  {string} username The current username
 *
 * @return {object}      An action object with a type of INIT_APP passing the repos
 */
export function initApp() {
  return {
    type: INIT_APP,
  };
}

export function setCurrentUser(payload) {
  return {
    type: SET_CURRENT_USER,
    payload,
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}

export function setLoading(payload) {
  return {
    type: SET_LOADING,
    payload,
  };
}

export function setLoaded(payload) {
  return {
    type: SET_LOADED,
    payload,
  };
}
