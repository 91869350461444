import React from 'react';
import PropTypes from 'prop-types';
import cs from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import BurgerIcon from 'icons/burger';
import LanguageSelector from 'components/LanguageSelector';
import styles from './styles.module.scss';
import LogoPng from '../../images/enola-logo.png';

export default function NavBar({ navigationItems, logOutComponent }) {
  const { pathname } = useLocation();

  return (
    <div className={styles.navbar}>
      <div className={styles.top}>
        <div className={styles.topLogoWrapper}>
          <img alt="logo navbar" src={LogoPng} />
          <BurgerIcon />
        </div>
        <div className={styles.programName}>Program_name</div>
      </div>
      <ul className={styles.itemList}>
        {navigationItems.map((item, index) => {
          const elementKey = `${index}-${item.label}`;
          const isActive = pathname.includes(item.navigateTo);

          return (
            <li key={elementKey}>
              <Link
                className={cs(styles.item, {
                  [styles.disabled]: item?.disabled,
                  [styles.active]: isActive,
                })}
                to={item.navigateTo}
              >
                <div className={styles.itemIcon}>{item.icon}</div>
                <div className={styles.itemLabel}>{item.label}</div>
              </Link>
            </li>
          );
        })}
      </ul>
      <a className={styles.item} onClick={logOutComponent.clickHandler} href="#">
        <div className={styles.itemIcon}>{logOutComponent.icon}</div>
        <div className={styles.itemLabel}>{logOutComponent.label}</div>
      </a>
    </div>
  );
}

NavBar.propTypes = {
  navigationItems: PropTypes.array,
  logOutComponent: PropTypes.object,
};
