import { createSelector } from 'reselect';
import get from 'lodash/fp/get';

import { initialState } from './reducer';

const selectDashboard = (state) => state.dashboardPage || initialState;

export const makeSelectLoading = () => createSelector(selectDashboard, get('isLoading'));
export const makeDashboardInfoSelector = () =>
  createSelector(selectDashboard, get('dashboardInfo'));
