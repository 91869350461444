export const PREFIX = 'enola';

export function getItem(name) {
  const raw = localStorage.getItem(`${PREFIX}.${name}`);

  try {
    return JSON.parse(raw);
  } catch (e) {
    return raw;
  }
}

export function setItem(name, data) {
  localStorage.setItem(`${PREFIX}.${name}`, JSON.stringify(data));
}

export function clear() {
  Object.keys(localStorage).forEach((key) => {
    if (new RegExp(`^${PREFIX}.`).test(key)) {
      localStorage.removeItem(key);
    }
  });
}
