import { parse, stringify } from 'qs';

const prefix = '/app';

function clear(path) {
  return path.replace(/(\/)\/+/g, '$1');
}

export default function basePath(path = 'families') {
  return clear([prefix, path].filter(Boolean).join('/'));
}

export function basePathWithSearch(path) {
  return `${basePath(path)}${window.location.search}`;
}

export function keepSearchParams(path) {
  return `${clear(path)}${window.location.search}`;
}

export function addFieldToSearchString(field, value) {
  const search = parse(window.location.search, { ignoreQueryPrefix: true });
  search[field] = value;
  return stringify(search, { addQueryPrefix: false });
}

export function removeFieldFromSearchString(field, addQueryPrefix = false) {
  const search = parse(window.location.search, { ignoreQueryPrefix: true });
  delete search[field];
  return stringify(search, { addQueryPrefix });
}

export function normalizeUrl(urlString) {
  try {
    const url = new URL(urlString);
    return url.toString();
  } catch {
    // if (process.env.NODE_ENV === 'development') {
    //   return `${process.env.PROXY_HOST}${urlString}`;
    // }

    return urlString;
  }
}
