import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function (props) {
  return (
    <SvgIcon viewBox="0 0 20 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* esling-disable-next-line */}
      <path d="M7 14L8.41 12.59L3.83 8H20V6H3.83L8.42 1.41L7 0L0 7L7 14Z" />
    </SvgIcon>
  );
}
