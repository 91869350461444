import React, { memo, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import basePath from 'utils/basePath';
import { enUS, heIL } from '@mui/x-data-grid/locales';
import buildColumns from './Component/columns';

function Content(props) {
  const { t, i18n } = useTranslation(['membership_requests', 'common']);
  const columns = useMemo(() => buildColumns({ t }), [i18n.language]);
  return (
    <Grid container sx={{ gap: 2 }}>
      <Grid item xs={12}>
        <Header
          backButtonPath={basePath(`families/${props.familyId}`)}
          title={t('requests_list')}
        />
      </Grid>

      <Grid item xs={12}>
        <DataGrid
          loading={props.isLoading}
          rowHeight={80}
          localeText={
            i18n.dir() === 'rtl' ?
              heIL.components.MuiDataGrid.defaultProps.localeText
            : enUS.components.MuiDataGrid.defaultProps.localeText
          }
          rows={props.membershipRequests}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20]}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          autoHeight
        />
      </Grid>
    </Grid>
  );
}

Content.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  membershipRequests: PropTypes.array.isRequired,
  familyId: PropTypes.string,
};

export default compose(
  memo,
  withStyles((theme) => ({
    tableHeader: {
      maxHeight: '80px !important',
      height: '80px !important',
      backgroundColor: theme.uiColors.neutral.second[100],
    },
  })),
)(Content);
