import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'utils/request';

import { ADD_MEMBERSHIP_REQUESTS, FETCH_MEMBERSHIP_REQUESTS } from './constants';
import { setLoading, setMembershipRequests } from './actions';

const apiInst = api();

function* fetchMembershipRequests({ id }) {
  yield put(setLoading(true));
  try {
    const { data } = yield call(apiInst.get, `/api/v2/families/${id}/membership_requests`, {
      params: { declined: true },
    });
    const { data: dataNoDeclined } = yield call(
      apiInst.get,
      `/api/v2/families/${id}/membership_requests`,
      { params: { declined: false } },
    );
    yield put(setMembershipRequests([...data, ...dataNoDeclined]));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* addMembershipRequests({ id }) {
  yield put(setLoading(true));
  try {
    yield call(apiInst.post, `/api/v2/families/${id}/membership_requests`, {
      phone_number: '375332303541',
      full_name: 'Shu222m11111 Nic1111k',
      task_id: 1,
      task_type: 'task',
    });
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* watchAll() {
  yield takeLatest(FETCH_MEMBERSHIP_REQUESTS, fetchMembershipRequests);
  yield takeLatest(ADD_MEMBERSHIP_REQUESTS, addMembershipRequests);
}

export default watchAll;
