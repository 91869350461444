/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { withStyles } from '@mui/styles';

import NotFoundPage from 'containers/Pages/NotFoundPage/Loadable';
import SignInPage from 'containers/Pages/SignInPage/Loadable';
import FamiliesPage, { NewFamilyPage, ViewFamilyPage } from 'containers/Pages/Families/Loadable';
import FamilyTasksPage from 'containers/Pages/FamilyTasks/Loadable';
import MembershipRequests from 'containers/Pages/MembershipRequests';
import Dashboard from 'containers/Pages/Dashboard';

import { LayoutsAuth, SimpleLayout } from 'containers/Layouts';

import Protected from './Protected';

import './global.css';

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.lang = i18n.language;
    document.dir = i18n.dir();
  }, [i18n.language]);
  return (
    <Routes>
      <Route exact path="app/sign-in" element={<SignInPage />} />
      <Route path="/" element={<Navigate to="/app" />} />
      <Route path="app/*" element={<Protected layoutComponent={SimpleLayout} />}>
        <Route path="" element={<Navigate to="families" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="families/:familyId/membershipRequests" element={<MembershipRequests />} />
        <Route path="families" element={<FamiliesPage />} />
        <Route path="families/new" element={<NewFamilyPage />} />
        <Route path="families/:id" element={<ViewFamilyPage />} />
        <Route path="families/:familyId/tasks" element={<FamilyTasksPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route path="*" element={<NotFoundPage layoutComponent={LayoutsAuth} />} />
    </Routes>
  );
}

export default compose(
  withStyles((theme) => ({
    '@global': {
      a: {
        color: theme.uiColors.neutral.first[800],
        fontWeight: 600,
        fontSize: 16,
      },
      body: {
        background: theme.palette.background.default,
      },
    },
  })),
)(App);
