import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function (props) {
  return (
    <SvgIcon viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* esling-disable-next-line */}
      <path
        d="M8.9998 5.99961H19.9998M3.7998 5.79961L4.5998 6.59961L6.5998 4.59961M3.7998 11.7996L4.5998 12.5996L6.5998 10.5996M3.7998 17.7996L4.5998 18.5996L6.5998 16.5996M8.9998 11.9996H19.9998M8.9998 17.9996H19.9998"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
}
