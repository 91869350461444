import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { alpha } from '@mui/material/styles';
import { withStyles } from '@mui/styles';

const circularSizeMap = {
  medium: 20,
  small: 16,
  large: 24,
};

const Button = React.forwardRef(
  ({ palette, children, pending, size, style, minWidth, ...props }, ref) => {
    const circularSize = circularSizeMap[size];

    const appliedStyle = { ...style, minWidth };

    return (
      <MuiButton
        color="primary"
        ref={ref}
        style={appliedStyle}
        disableElevation
        {...props}
        size={size}
        classes={{
          containedPrimary: props.classes[palette],
          outlinedPrimary: props.classes[`${palette}Outlined`],
          textPrimary: props.classes[`${palette}Text`],
          disabled: props.classes.disabled,
        }}
      >
        {pending ?
          <CircularProgress color="inherit" size={circularSize} />
        : children}
      </MuiButton>
    );
  },
);

Button.defaultProps = {
  size: 'medium',
  style: {},
};

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  palette: PropTypes.oneOf(['success', 'primaryLight', 'secondary', 'neutral', 'warning', 'error']),
  pending: PropTypes.bool,
  children: PropTypes.any,
  size: PropTypes.string,
  style: PropTypes.object,
  minWidth: PropTypes.number,
};

export default compose(
  memo,
  withStyles((theme) => ({
    success: {
      backgroundColor: theme.uiColors.semantic.success[800],
      color: theme.uiColors.semantic.success[100],

      '&:hover': {
        backgroundColor: theme.uiColors.semantic.success[900],
      },
    },
    successOutlined: {
      color: theme.uiColors.semantic.success[900],
      borderColor: alpha(theme.uiColors.semantic.success[900], 0.5),

      '&:hover': {
        borderColor: theme.uiColors.semantic.success[900],
      },
    },
    primaryLight: {
      backgroundColor: theme.uiColors.neutral.first[100],
      border: `1px solid ${theme.uiColors.neutral.first[200]}`,
      color: theme.uiColors.neutral.first[1000],
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: theme.uiColors.neutral.first[200],
        boxShadow: 'none',
      },
    },
    secondaryOutlined: {
      backgroundColor: theme.palette.background.default,
      border: `2px solid ${theme.uiColors.neutral.second[900]}`,
      color: theme.uiColors.neutral.first[1000],
      boxShadow: 'none',

      '&:hover': {
        border: `2px solid ${theme.uiColors.neutral.second[900]}`,
        color: theme.uiColors.neutral.first[1000],
        boxShadow: 'none',
      },
    },
    neutral: {
      boxShadow: 'none',
      backgroundColor: theme.palette.background.default,
      color: theme.uiColors.neutral.second[800],
      border: `0.5px solid ${theme.uiColors.neutral.second[200]}`,

      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.uiColors.neutral.second[800],
        border: `0.5px solid ${theme.uiColors.neutral.second[200]}`,
      },
    },
    neutralText: {
      boxShadow: 'none',
      backgroundColor: theme.palette.background.default,
      color: theme.uiColors.neutral.first[800],
      '&:hover': {
        background: 'none',
        boxShadow: 'none',
        color: theme.uiColors.neutral.first[1000],
      },
      padding: 0,
    },
    warningText: {
      color: theme.uiColors.semantic.warning[900],
    },
    errorText: {
      color: theme.uiColors.semantic.error[800],
    },
    disabled: {
      border: 'none',
    },
  })),
)(Button);
