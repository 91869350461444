import cronstrue from 'cronstrue';

export const dateFormat = (
  date,
  lang = 'en-US',
  options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
) => {
  if (date) {
    return new Date(date).toLocaleDateString(lang, options);
  }

  return '';
};

export const cronToString = (string = '') => {
  try {
    let schedule = string;
    if (schedule.includes('Fr')) {
      schedule = schedule.replace('Fr', 'Fri');
    }

    return cronstrue.toString(schedule);
  } catch (e) {
    console.error('cronToString', e);
    console.error('source', string);

    return '';
  }
};
