import { getStore } from 'configureStore';

import { clear as clearState } from 'containers/App/actions';
import { PREFIX, clear } from './localstorageUtil';

export function getSession() {
  try {
    const session = JSON.parse(localStorage.getItem(`${PREFIX}.session`));

    return session || {};
  } catch (e) {
    console.error(e);
    return {};
  }
}

export function clearSession() {
  try {
    const store = getStore();

    store.dispatch(clearState());
    clear();
  } catch (e) {
    console.error(e);
  }
}

export function setSession(user, token, createdAt) {
  const currentSession = { user: { ...user, token }, createdAt };
  localStorage.setItem(`${PREFIX}.session`, JSON.stringify(currentSession));
}
