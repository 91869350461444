import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import CardInfo from './Component/CardInfo/index';

function Content({ dashboardInfo, isLoading }) {
  const { t } = useTranslation(['dashboard_page', 'header_main']);
  return (
    <Grid container sx={{ gap: 5 }}>
      <Grid item xs={12}>
        <Header title={t('header_main:dashboard')} />
      </Grid>

      <Box width="100%" height="100%" gap={4} display="flex" flexWrap="wrap">
        <CardInfo
          isLoading={isLoading}
          from="familes"
          displayValue={dashboardInfo.length}
          title={t('totalFamilies')}
        />
        <CardInfo
          isLoading={isLoading}
          from="volunteers"
          displayValue={1112}
          title={t('totalVolunteers')}
        />
        <CardInfo isLoading={isLoading} from="tasks" displayValue={244} title={t('totalTasks')} />
      </Box>
    </Grid>
  );
}

Content.propTypes = {
  dashboardInfo: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default memo(Content);
