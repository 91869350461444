import {
  FETCH_MEMBERSHIP_REQUESTS,
  SET_MEMBERSHIP_REQUESTS,
  SET_LOADING,
  ADD_MEMBERSHIP_REQUESTS,
} from './constants';

export function fetchMembershipRequests(id) {
  return {
    type: FETCH_MEMBERSHIP_REQUESTS,
    id,
  };
}

export function addMembershipRequests(id) {
  return {
    type: ADD_MEMBERSHIP_REQUESTS,
    id,
  };
}

export function setMembershipRequests(data) {
  return {
    type: SET_MEMBERSHIP_REQUESTS,
    payload: data,
  };
}

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    payload: isLoading,
  };
}
