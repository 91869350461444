import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function (props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* esling-disable-next-line */}
      <path
        d="M12 13V2L20 6L12 10"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5607 10.2216C21.0928 11.8618 21.1426 13.6203 20.7041 15.2879C20.2656 16.9555 19.3573 18.4621 18.0872 19.6283C16.8172 20.7946 15.2388 21.5714 13.5399 21.8665C11.841 22.1615 10.0932 21.9623 8.50424 21.2925C6.91532 20.6228 5.55224 19.5107 4.57717 18.0885C3.6021 16.6664 3.05609 14.9941 3.00409 13.2706C2.9521 11.547 3.39631 9.84486 4.28388 8.36653C5.17145 6.8882 6.46502 5.69595 8.01068 4.93164"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00141 9.99695C7.50031 10.664 7.17409 11.4458 7.05245 12.2711C6.93082 13.0965 7.01764 13.9392 7.30501 14.7224C7.59238 15.5056 8.07116 16.2045 8.69774 16.7553C9.32431 17.3062 10.0788 17.6915 10.8923 17.8761C11.7059 18.0608 12.5528 18.0389 13.3558 17.8125C14.1587 17.5862 14.8923 17.1625 15.4896 16.58C16.0869 15.9976 16.529 15.275 16.7756 14.478C17.0222 13.681 17.0655 12.8349 16.9014 12.0169"
        stroke="currentColor"
        fill="transparent"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
}
