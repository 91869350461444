import produce from 'immer';
import { SET_LOADING, SET_MEMBERSHIP_REQUESTS } from './constants';

export const initialState = {
  isLoading: false,
  membershipRequests: [],
};

/* eslint-disable default-case, no-param-reassign */
const membershipRequestsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_MEMBERSHIP_REQUESTS: {
        draft.membershipRequests = action.payload;
        break;
      }
      case SET_LOADING: {
        draft.isLoading = action.payload;
        break;
      }
    }
  });

export default membershipRequestsReducer;
