import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles.module.scss';

function LoadingIndicator({ size = 40 }) {
  return (
    <div className={styles.wrapper}>
      <CircularProgress size={size} />
    </div>
  );
}

LoadingIndicator.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LoadingIndicator;
