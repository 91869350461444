/**
 *
 * LayoutsAuth
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/system/Container';
import defaultLogo from 'images/logo_enola.png';
import Image from 'components/Image';
import styles from './styles.module.scss';

function ImageStub() {
  return <img data-testid="logoLink" alt="Enola logo" src={defaultLogo} className={styles.logo} />;
}

function LayoutsAuth(props) {
  return (
    <div className={styles.container}>
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <Grid item xs={12} alignSelf="end">
          <Container className={styles.content}>
            <Image ImageStub={ImageStub} />
            {props.children}
          </Container>
        </Grid>

        <Grid item xs={12} alignSelf="end">
          <Grid container justifyContent="center" alignItems="center" className={styles.footer}>
            <Typography variant="body1">© {new Date().getFullYear()} Enola Ltd</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

LayoutsAuth.propTypes = {
  children: PropTypes.any,
};

export default memo(LayoutsAuth);
